@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

html {
  @apply font-serif bg-text-white text-text-black;
}

blockquote {
  background-image: url("../../public/icons/blockquote.png");
  background-size: 5px;

  @apply pl-8 italic bg-repeat-y;
}

footer {
  background-image: url("../../public/images/dot-bg.png");
  background-size: 10px;
}

.dots-bg {
  background-image: url("../../public/images/dots-bg.png");
  background-position: 0 100.1%; /* Chrome antialias bug */
  background-size: 372px;

  @apply bg-repeat-x;
}

.break-line {
  background-image: url("../../public/icons/line.png");
  background-size: 110px;

  @apply my-16 bg-no-repeat bg-center w-full h-2 tablet:hidden;
}

.red-rhombus-badge {
  @apply relative;
}

.red-rhombus-badge::after {
  @apply w-1.5 h-1.5 block absolute right-0 top-0 rotate-45 bg-red-warning;

  content: "";
}

.red-linear-gradient {
  width: 50px;
  height: 50px;
  background: linear-gradient(142deg, rgba(255,0,24,0.5) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
}


