@font-face {
  font-family: "Factor A";
  src: url("../../public/fonts/FactorA-Regular.woff2") format("woff2"),
      url("../../public/fonts/FactorA-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Factor A";
  src: url("../../public/fonts/FactorA-Medium.woff2") format("woff2"),
      url("../../public/fonts/FactorA-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Factor A";
  src: url("../../public/fonts/FactorA-Bold.woff2") format("woff2"),
      url("../../public/fonts/FactorA-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SangBleu Republic";
  src: url("../../public/fonts/SangBleuRepublic-Regular.woff2") format("woff2"),
      url("../../public/fonts/SangBleuRepublic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SangBleu Republic";
  src: url("../../public/fonts/SangBleuRepublic-Medium.woff2") format("woff2"),
      url("../../public/fonts/SangBleuRepublic-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SangBleu Republic';
  src: url('../../public/fonts/SangBleuRepublic-Italic.woff2') format('woff2'),
      url('../../public/fonts/SangBleuRepublic-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
