@import 'swiper/css';
@import "swiper/css/grid";
@import 'swiper/css/pagination';

.swiper-slide {
  @apply w-auto h-auto;
}

.swiper-pagination-bullet {
  background: url("../../public/icons/carousel-bullet.svg");

  @apply w-carousel-bullet h-carousel-bullet opacity-100;
}
.swiper-pagination-bullet-active {
  background: url("../../public/icons/carousel-bullet-active.svg");
}

.swiper-button-disabled {
  @apply transition opacity-25;
}
