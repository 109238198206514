@tailwind components;

@layer components {
  .text-display {
    font-size: 80px;
    line-height: 1;
    letter-spacing: -0.02em;
  
    @apply font-sans font-normal;
  }
  
  h1, .text-headline-1 {
    font-size: 52px;
    line-height: 1;
    letter-spacing: -0.02em;
  
    @apply font-sans font-normal;
  }
  
  h2, .text-headline-2 {
    font-size: 48px;
    line-height: 1.1;
    letter-spacing: -0.02em;
  
    @apply font-sans font-normal;
  }
  
  h3, .text-headline-3 {
    font-size: 34px;
    line-height: 1;
    letter-spacing: -0.02em;
  
    @apply font-sans font-bold;
  }
  
  h4, .text-headline-4 {
    font-size: 34px;
    line-height: 1.3;
    letter-spacing: -0.02em;
  
    @apply font-sans font-normal;
  }
  
  h5, .text-headline-5 {
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 0.01em;
  
    @apply font-sans font-normal;
  }
  
  h6, .text-headline-6 {
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: -0.01em;
  
    @apply font-sans font-normal;
  }
  
  .text-body-1 {
    font-size: 24px;
    line-height: 1.6;
    letter-spacing: 0.01em;
  
    @apply font-serif font-medium;
  }
  
  .text-body-2 {
    font-size: 24px;
    line-height: 1.6;
    letter-spacing: 0.01em;
  
    @apply font-serif font-normal;
  }
  
  .text-body-3 {
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 0.01em;
  
    @apply font-serif font-medium;
  }
  
  .text-body-4 {
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 0.01em;
  
    @apply font-serif font-normal;
  }
  
  .text-body-5 {
    font-size: 16px;
    line-height: 1.7;
    letter-spacing: 0.01em;
  
    @apply font-serif font-medium;
  }
  
  .text-body-6 {
    font-size: 16px;
    line-height: 1.7;
    letter-spacing: -0.01em;
  
    @apply font-serif font-normal;
  }
  
  .text-caption-1 {
    font-size: 18px;
    line-height: 1;
    letter-spacing: 0.01em;
  
    @apply font-sans font-normal;
  }
  
  .text-caption-2 {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 0.01em;
  
    @apply font-sans font-normal;
  }
  
  .text-caption-3 {
    font-size: 13px;
    line-height: 1;
    letter-spacing: 0.01em;
  
    @apply font-sans font-medium;
  }
  
  .text-caption-4 {
    font-size: 13px;
    line-height: 1;
    letter-spacing: 0.01em;
  
    @apply font-sans font-normal;
  }

  .text-caption-5 {
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: -0.02em;

    @apply font-sans font-light;
  }

  .text-caption-6 {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: -0.02em;

    @apply font-sans font-light;
  }

  .text-xxs {
    font-size: 10px;
    letter-spacing: -0.02em;

    @apply font-sans font-light;
  }

  button, .text-button {
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 0.02em;
  
    @apply font-sans font-normal;
  }
  
  a, .text-link {
    font-size: 13px;
    letter-spacing: 0.02em;
  
    @apply font-sans font-medium underline;
  }

  p {
    @apply mt-6 text-body-4;
  }

  ul.list-rhombus {
    @apply mt-4 space-y-3;
  }
  ul.list-rhombus li {
    @apply pl-5 text-body-4 relative;
  }
  ul.list-rhombus li:before {
    content: "";
      
    @apply w-list-bullet h-list-bullet bg-black-900 rotate-45 absolute top-3 left-0 block;
  }

  blockquote {
    @apply text-body-4;
  }
}
